import React, { useEffect } from "react";
import { Box, useToast } from "@chakra-ui/react";
import {
	ERROR_MSG,
	INFO_MSG,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	REGISTER_FAIL,
	REGISTER_SUCCESS,
	SUCCESS_MSG,
} from "../utils/store/store";

export const ToastNotification = (toastMsg: any) => {
	const toast = useToast();

	useEffect(() => {
		switch (toastMsg?.toastMsg.message?.case) {
			case LOGIN_FAIL:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"error"
				);
				break;
			case LOGIN_SUCCESS:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"success"
				);
				break;
			case REGISTER_SUCCESS:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"success"
				);
				break;
			case REGISTER_FAIL:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"error"
				);
				break;
			case ERROR_MSG:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"error"
				);
				break;
			case SUCCESS_MSG:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"success"
				);
				break;
			case INFO_MSG:
				triggerToast(
					toastMsg.toastMsg.message.title,
					toastMsg.toastMsg.message.data,
					"info"
				);
				break;
		}
	}, [toastMsg]);

	const triggerToast = (title: string = "", message: string, status: any) => {
		toast.closeAll();
		if (title.length > 0)
			toast({
				title: title,
				description: message,
				status: status,
				isClosable: true,
				position: "top-right",
				variant: "left-accent",
			});
		else
			toast({
				title: message,
				status: status,
				isClosable: true,
				position: "top-right",
				variant: "left-accent",
			});
	};
	return <Box></Box>;
};
