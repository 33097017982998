import React from "react";
import { Box, Image, Text, Flex, Stack, useMediaQuery } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import "./swiper-styles.css";
import PageLoaderURL from "../img/loader.gif";
import "swiper/swiper.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { PageLoading } from "../packages";
import KKonnect_White from "../img/Kkonnect_White.webp";
import GridIcon from "../img/Grid.webp";
import checkVerified from "../img/check-verified.webp";
import ShieldZap from "../img/shield-zap.webp";
import { useLocation } from "react-router-dom";
import Onboarding from "./Onboarding";
import { LuMousePointerClick } from "react-icons/lu";

export const PublicLayout: React.FC<any> = ({ Component, ...rest }) => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
	const [showOnBoarding, setShowOnBoarding] = React.useState<boolean>(true);
	const location = useLocation();
	const page = location.pathname.split("/")[1];
	const adContent = [
		{
			icon: ShieldZap,
			text: "Instant Cost Estimation with Detailed Breakdown from Your CAD File",
		},
		{
			icon: checkVerified,
			text: "Get Manufacturing Recommendations Tailored to Your CAD Designs",
		},
		{
			icon: GridIcon,
			text: "Advanced CAD Viewer with Quick DFM Analysis at Your Fingertips",
		},
	];

	const Pentagon = () => (
		<svg width="100%" height="100%" viewBox="0 0 100 100">
			<path
				d="M50 5
           L95 38
           A5 5 0 0 1 97 42
           L82 95
           A5 5 0 0 1 77 98
           H23
           A5 5 0 0 1 18 95
           L3 42
           A5 5 0 0 1 5 38
           Z"
				fill="#DCF0FF"
			/>
		</svg>
	);
	return (
		<Box>
			{isMobile && page === "register" && showOnBoarding ? (
				<Onboarding skipOnboarding={() => setShowOnBoarding(false)} />
			) : isMobile ? (
				<Box width="100%" h="100vh">
					<Component {...rest} />
				</Box>
			) : (
				<React.Fragment>
					<Flex
						justifyContent={
							["register", "verifyEmail"].includes(page)
								? "space-between"
								: "center"
						}
						bg={
							"radial-gradient(#4E88CF .6px, transparent 1.2px), linear-gradient(to right, #0F56AA, #1462BF, #1A71D9)"
						}
						backgroundSize="12px 12px, cover"
						minH="100vh"
						zIndex={10}
						alignItems="center"
						position="relative"
						overflow="hidden"
					>
						<Box
							position="absolute"
							width="27.5rem"
							height="27.5rem"
							top="-16rem"
							right="-5.5rem"
							opacity="10%"
							transform="rotate(110deg)"
						>
							<Pentagon />
						</Box>
						<Box
							position="absolute"
							width="27.5rem"
							height="27.5rem"
							transform="rotate(30deg)"
							bottom="3rem"
							right="-15rem"
							opacity="10%"
						>
							<Pentagon />
						</Box>
						{["register", "verifyEmail"].includes(page) && (
							<Box pl="10">
								<Image src={KKonnect_White} width="29%" />
								<Box>
									<Text
										color="#01FFAA"
										fontWeight={700}
										fontSize="3.5rem"
										lineHeight="4.375rem"
										mt="1rem"
									>
										CNC Costing in <br />
										Seconds
									</Text>
									<Box position="relative" display="inline-block" pr="2rem">
										<Text
											lineHeight="1.75rem"
											fontSize="1.25rem"
											color="#fff"
											fontWeight={600}
										>
											Save Time, Win Orders: From CAD to Quote
										</Text>
										<Box position="absolute" right="0">
											<Box width="130px" position="relative">
												<Text
													width="8.125rem"
													height="2.125rem"
													display="flex"
													alignItems="center"
													justifyContent="center"
													textAlign="center"
													bg="#01FFAA"
													borderRadius="50px"
													transform="rotate(-4.08deg)"
													fontSize="1.25rem"
													color="#0F5CAA"
													fontWeight={600}
													lineHeight="1.75rem"
												>
													in a click!
												</Text>
												<Flex
													justifyContent="flex-end"
													position="absolute"
													left="7rem"
													top=".75rem"
												>
													<LuMousePointerClick
														color="#fff"
														fill="#fff"
														size="1.5rem"
													/>
												</Flex>
											</Box>
										</Box>
									</Box>
								</Box>
								<Stack spacing="2rem" mt="4rem">
									{adContent?.map((item: any, index: number) => (
										<Stack gap=".5rem">
											<Image src={item.icon} width="2rem" alt="icon" />
											<Text color="#fff" lineHeight="1.5rem" fontWeight={600}>
												{item.text}
											</Text>
										</Stack>
									))}
								</Stack>
							</Box>
						)}
						<Flex
							flexDir="column"
							width={["register", "verifyEmail"].includes(page) ? "50%" : "40%"}
							m="2em"
							alignItems="center"
							justifyContent="center"
							gap="2rem"
						>
							{!["register", "verifyEmail"].includes(page) && (
								<Image src={KKonnect_White} width="27%" />
							)}
							<Box
								width="100%"
								h="inherit"
								backgroundColor="rgba(255, 255, 255, 0.15)"
								backdropFilter="blur(10px)"
								borderRadius="1rem"
								boxShadow={"lg"}
								border="1px solid #ffffff40"
							>
								<Component {...rest} />
							</Box>
						</Flex>
						{pageLoadingData?.loading && (
							<PageLoading
								pageLoading={pageLoadingData}
								pageLoaderURL={PageLoaderURL}
							/>
						)}
					</Flex>
				</React.Fragment>
			)}
		</Box>
	);
};
