import React, { useEffect, useRef } from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { motion, AnimatePresence } from "framer-motion";

const useOutsideClick = (ref, callback) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("touchstart", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [ref, callback]);
};

interface OverlayProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

export const Overlay = ({
	isOpen,
	onClose,
	children,
	...props
}: OverlayProps) => {
	const overlayRef = React.useRef<HTMLDivElement>(null);
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, onClose);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					{...props}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.3 }}
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						background: "rgba(0, 0, 0, 0.1)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						overflow: "hidden",
						zIndex: 1000,
					}}
					ref={overlayRef}
				>
					<Box
						ref={wrapperRef}
						position="relative"
						background="white"
						borderRadius="md"
						overflow="hidden"
						zIndex={99999}
					>
						<IconButton
							icon={<CloseIcon />}
							aria-label="Close"
							position="absolute"
							top={2}
							right={2}
							onClick={onClose}
						/>
						{children}
					</Box>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
