import axios from "axios";

export const updateSupplierCustomRates = (
	method: string,
	docId: string,
	data: any
) => {
	if (method === "create") {
		return axios.post(`/api/resource/Custom Pricing Matrix`, data);
	} else return axios.put(`/api/resource/Custom Pricing Matrix/${docId}`, data);
};
