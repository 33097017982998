import {
	Box,
	Button,
	Flex,
	FlexProps,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import React, { lazy, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
	BsChevronDown,
	BsCloudUploadFill,
	BsFillPersonFill,
	BsGearWideConnected,
} from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { GrHomeRounded } from "react-icons/gr";
import { IoPersonOutline } from "react-icons/io5";
import {
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
	MdArrowBackIosNew,
} from "react-icons/md";
import { ProductFruits } from "react-product-fruits";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Mixpanel } from "./Mixpanel.js";
import checkVerifiedBlue from "./img/check-verified-blue.svg";
import KKonnectLogo from "./img/kklogo.png";
import FileUploadPageLoader from "./img/loader.gif";
import ShieldZapGreen from "./img/shield-zap-green.svg";
import {
	AppDispatch,
	BasicDialog,
	clearSubscriptionPlan,
	ERROR_MSG,
	formatErrorMessage,
	frappeMethodService,
	logoutAce,
	normalDate,
	PageLoading,
	SET_MESSAGE,
	setPageLoading,
	setSubscriptionPlan,
	SUCCESS_MSG,
} from "./packages";
const PricingModel = lazy(() => import("./common/PricingModel"));

interface IconTextProps extends FlexProps {
	Icon: typeof GrHomeRounded;
	text: string;
	active?: boolean;
	link: string;
	disabled?: boolean;
}

interface IconMiniProps extends FlexProps {
	Icon: typeof GrHomeRounded;
	active?: boolean;
	link: string;
	text: string;
}
const IconText = ({
	Icon,
	text,
	link,
	disabled,
	active = false,
	...rest
}: IconTextProps) => {
	const width = window.innerWidth;

	if (active) {
		return (
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				width="100%"
				bg={"rgba(0, 135, 238, 0.1)"}
				borderRadius="md"
				as={Link}
				to={link}
			>
				<Icon size="20px" color="#0087ee" />
				<Box ml="11px">
					<Text
						textStyle="primary.text"
						fontWeight={"500"}
						fontSize="16px"
						color="#0087ee"
					>
						{text}
					</Text>
				</Box>
			</Flex>
		);
	}

	if (disabled) {
		return (
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				borderRadius={"10px"}
			>
				<Icon size="20px" />
				<Box ml="11px">
					<Text textStyle="primary.text" fontSize="16px">
						{text}
					</Text>
				</Box>
			</Flex>
		);
	}

	return (
		<Link to={link}>
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				borderRadius={"10px"}
				width="100%"
			>
				<Icon size="20px" />
				<Box ml="11px">
					<Text textStyle="primary.text" fontSize="16px">
						{text}
					</Text>
				</Box>
			</Flex>
		</Link>
	);
};

const IconMini = ({
	Icon,
	link,
	text,
	active = false,
	...rest
}: IconMiniProps) => {
	if (active) {
		return (
			<Flex
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				gap="0.3em"
				{...rest}
				bg={"blue.50"}
				ml="0"
				as={Link}
				to={link}
				p="0.6em"
				borderTopRightRadius="md"
				borderBottomRightRadius="md"
				width="100%"
				borderLeft="4px solid #0087ee"
			>
				<Icon size="26px" color="#0087ee" />
				<Text fontSize="11px" fontWeight={"700"} color="#0087ee">
					{text}
				</Text>
			</Flex>
		);
	}
	return (
		<Flex
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			gap="0.3em"
			{...rest}
			borderRadius={"10px"}
			ml="0"
			p="0.6em"
			as={Link}
			to={link}
			width="100%"
		>
			<Icon size="26px" />
			<Text fontSize="11px">{text}</Text>
		</Flex>
	);
};

export const AppShell = ({
	children: Component,
	imageURL,
	pageLoaderURL,
	notifications,
	navigateToDoc,
}: {
	children?: React.LazyExoticComponent<React.FC<any>>;
	imageURL: string;
	pageLoaderURL: string;
	notifications: any[];
	navigateToDoc: Function;
}) => {
	const location = useLocation();
	const pathname = React.useMemo(
		() => location.pathname.split("/"),
		[location.pathname]
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [pageLoadingData, subscriptionData] = useSelector((state: any) => [
		state.pageLoading,
		state.subscriptionPlan,
	]);
	const dispatch = useDispatch<AppDispatch>();

	const [activeTab, setActiveTab] = React.useState<String>("/" + pathname[1]);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const [collapsed, setCollapsed] = React.useState(true);
	const [menu, setMenu] = React.useState(false);
	const [showPlans, setShowPlans] = React.useState<boolean>(false);
	const [showPlanUpgradeConfirmBox, setShowPlanUpgradeConfirmBox] =
		React.useState<boolean>(false);
	const [selectedPlan, setSelectedPlan] = React.useState<string>("");
	const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
	const [subscriptionColorTheme, setSubscriptionColorTheme] = React.useState({
		text_color: "#0087EE",
		box_border: "1px dashed #0087EE70",
		box_background_color: "#0087EE10",
		button_background_color: "#0087EE",
		button_hover_color: "#0066b4",
	});
	const [pageHeader, setPageHeader] = React.useState<string>(
		"Automated Costing Engine"
	);

	const errorHandler = (error: any) => {
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
		dispatch({
			type: SET_MESSAGE,
			payload: { case: ERROR_MSG, data: formatErrorMessage(error) },
		});
	};

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const getCurrentSubScriptionData = async () => {
		frappeMethodService(
			"credit_based_costing.api.pricing_plans.get_plan_detials",
			{},
			""
		)
			.then((res: any) => {
				dispatch(
					setSubscriptionPlan({
						plan: res.data?.plan_name || "Free Plan",
						used_file_uploads:
							res.data?.total_fileupload_limit -
								res.data?.avaliable_fileupload_limit || 0,
						allocated_file_uploads: res.data?.total_fileupload_limit || 0,
						plan_status: res.data?.bool_value ? "Activated" : "Expired",
						plan_expiry_date: normalDate(res.data?.plan_expiry_on) || "",
					})
				);
				if (res.data?.plan_name?.toLowerCase().includes("pro"))
					setSubscriptionColorTheme({
						text_color: "#4BA18A",
						box_border: "1px dashed #4BA18A70",
						box_background_color: "#4BA18A10",
						button_background_color: "#4BA18A",
						button_hover_color: "#38846D",
					});
			})
			.catch((error) => {
				dispatch(clearSubscriptionPlan());
				dispatch({
					type: SET_MESSAGE,
					payload: { case: ERROR_MSG, data: formatErrorMessage(error) },
				});
			});
	};

	const changePlan = async () => {
		setButtonLoading(true);
		await frappeMethodService(
			"credit_based_costing.api.pricing_plans.enrole_plan",
			{
				plan_name: selectedPlan,
			},
			"post_with_params"
		)
			.then((response: any) => {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "Upgrade request Submitted!",
					},
				});
				Mixpanel.track("Upgrade Requested", {
					page_name: window.location.pathname.split("/")[1],
					page_url: window.location.href,
					user_id: Cookies.get("user_id"),
					chosen_plan: selectedPlan,
				});
				(window as any).dataLayer?.push({
					event: "Upgrade Requested",
					chosen_plan: selectedPlan,
					user_email: Cookies.get("user_id"),
					user_name: Cookies.get("full_name"),
				});
				getCurrentSubScriptionData();
			})
			.catch((error: any) => {
				setShowPlans(true);
				errorHandler(error);
			});
		setButtonLoading(false);
		setShowPlanUpgradeConfirmBox(false);
	};

	// useEffect(() => {
	// 	getCurrentSubScriptionData();
	// }, [pathname[1]]);

	useEffect(() => {
		(window as any).dataLayer?.push({
			event: "VirtualPageView",
			pageUrl: window.location.href,
			pageTitle: window.location.pathname,
			UserEmailId: Cookies.get("user_id"),
			userName: Cookies.get("full_name"),
		});
		Mixpanel.track("VirtualPageView", {
			page_url: window.location.href,
			page_title: window.location.pathname,
			user_email: Cookies.get("user_id"),
			user_name: Cookies.get("full_name"),
		});
	}, [window.location.pathname]);

	useEffect(() => {
		setActiveTabBackground();
	}, [pathname[1]]);

	useEffect(() => {
		switch (pathname[1]) {
			case "fileupload":
				setPageHeader("Automated Costing Engine");
				break;
			case "itemlibrary":
				if (pathname[2]) setPageHeader("Configure Item");
				else setPageHeader("Item Library");
				break;
			case "myprofile":
				if (searchParams?.get("tab") === "materialRate")
					setPageHeader("Edit Material Rate");
				else if (searchParams?.get("tab") === "machiningRate")
					setPageHeader("Edit Machine Rate");
				else setPageHeader("Profile");
				break;
			default:
				break;
		}
	}, [pathname]);

	useEffect(() => {
		if (isMobileTablet) {
			setCollapsed(true);
		}
	}, [isMobileTablet]);

	const setActiveTabBackground = () => {
		// setActiveTab("/" + pathname[1]);
		switch (pathname[1]) {
			case "fileupload":
				setActiveTab("/fileupload");
				document.title = "Home";
				break;
			case "itemlibrary":
				setActiveTab("/itemlibrary");
				document.title = "Item Library";
				break;
			case "myprofile":
				setActiveTab(`/myprofile`);
				if (searchParams?.get("tab") === "materialRate")
					document.title = "Material Rates";
				else if (searchParams?.get("tab") === "machiningRate")
					document.title = "Machining Rates";
				else document.title = "My Profile";
				break;
			default:
				break;
		}
	};

	const menuItems = [
		{
			icon: BsCloudUploadFill,
			text: "File upload",
			sideBarText: "File Upload",
			link: "/fileupload",
		},
		{
			icon: BsGearWideConnected,
			text: "Item Library",
			sideBarText: "Item Library",
			link: "/itemlibrary",
		},
		{
			icon: BsFillPersonFill,
			sideBarText: "My Profile",
			text: "My Profile",
			link: "/myprofile",
		},
	];

	const handleSignout = () => {
		(window as any).dataLayer?.push({
			event: "User Signout",
			page_url: window.location.href,
			page_exit_url: window.location.href,
			page_exit_name: window.location.pathname,
			user_id: Cookies.get("user_id"),
		});
		Mixpanel.track("signOut", {
			page_url: window.location.href,
			page_exit_url: window.location.href,
			page_exit_name: window.location.pathname,
			user_id: Cookies.get("user_id"),
		});
		//logout here
		dispatch(logoutAce());
	};

	const variants = {
		open: { opacity: 1, x: "0" },
		closed: { opacity: 1, x: "0" },
	};

	const sideVariants = {
		closed: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: -1,
			},
		},
		open: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: 1,
			},
		},
	};

	const subscriptionStatus = () => {
		return (
			<Flex
				flexDirection="column"
				justifyContent="center"
				border={subscriptionColorTheme?.box_border}
				bg={subscriptionColorTheme?.box_background_color}
				width={collapsed ? "87.5%" : "98%"}
				borderRadius=".5rem"
				p="1rem .5rem"
				mb="1.375rem"
				textAlign={collapsed ? "center" : "left"}
			>
				<Text
					fontSize="14px"
					fontWeight="semibold"
					color={subscriptionColorTheme?.text_color}
					wordBreak="break-word"
				>
					{subscriptionData?.plan}
				</Text>
				<Flex
					alignItems="center"
					justifyContent={collapsed ? "center" : "flex-start"}
					flexWrap="wrap"
					gap="0rem 0.2rem"
				>
					<Text fontSize=".75rem" color="#00000080">
						Expires on
					</Text>
					<Text
						fontSize=".75rem"
						color="#00000080"
						wordBreak="keep-all"
						whiteSpace="nowrap"
						overflowWrap="normal"
					>
						{subscriptionData?.plan_expiry_date}
					</Text>
				</Flex>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					flexWrap="wrap"
					mt=".75rem"
					gap=".25rem"
				>
					<Box w={collapsed ? "100%" : "47%"}>
						<Text fontSize={collapsed ? ".75rem" : ".875rem"}>
							File uploads usage
						</Text>
						<Text fontSize=".625rem" color="#00000070">
							per month
						</Text>
					</Box>
					<Text
						fontSize=".875rem"
						color={subscriptionColorTheme?.text_color}
						bg="#fff"
						borderRadius="5rem"
						p=".25rem"
						w={collapsed ? "100%" : "47%"}
						textAlign="center"
					>{`${subscriptionData?.used_file_uploads}${
						collapsed ? " / " : " out of "
					}${subscriptionData?.allocated_file_uploads}`}</Text>
				</Flex>
				<Text
					display={collapsed ? "none" : ""}
					color="#00000070"
					fontSize=".625rem"
					mt=".5rem"
				>
					This plan will be renewed next month
				</Text>
				<Button
					className="aceShowSubscriptionPlansBtn"
					id="ace_show_subscription_plans_btn"
					children={
						subscriptionData.plan?.toLowerCase().includes("pro")
							? "Change Plan"
							: "Upgrade Plan"
					}
					sx={{
						whiteSpace: "normal",
						overflowWrap: "break-word",
					}}
					color="#fff"
					bg={subscriptionColorTheme?.text_color}
					_hover={{ bg: subscriptionColorTheme?.button_hover_color }}
					size="md"
					fontSize={collapsed ? "sm" : "md"}
					width="100%"
					mt=".875rem"
					onClick={() => setShowPlans(true)}
				/>
				<Text
					color={subscriptionColorTheme?.text_color}
					textAlign="center"
					fontSize=".75rem"
					mt={collapsed ? ".5rem" : ".625rem"}
					cursor="pointer"
					onClick={() => setShowPlans(true)}
				>
					View All Plans
				</Text>
			</Flex>
		);
	};

	return (
		<Flex bg="#f3f6f9" height="100vh" overflow="hidden">
			{!(collapsed && isMobileTablet) && (
				<Box
					w={
						collapsed
							? isMobileTablet
								? "0%"
								: "7%"
							: isMobileTablet
							? "auto"
							: "20%"
					}
					as={motion.div}
					display="flex"
					flexShrink="0"
					zIndex={1001}
					h="100%"
					position={isMobileTablet ? "absolute" : "relative"}
					flexDirection="column"
					justifyContent="space-between"
					animate={{ x: "0" }}
					transition={{ type: "inertia" }}
					variants={variants}
					initial={{ x: "-100%" }}
					exit={{ x: "-100%" }}
					bg="#fff"
					overflow={"auto"}
					borderRight={isMobileTablet ? ".1px solid #f3f6f9" : ""}
				>
					<motion.div
						animate={collapsed ? "closed" : "open"}
						variants={variants}
					>
						<Flex
							flexDirection="column"
							w="100%"
							justifyContent="space-between"
						>
							{collapsed ? (
								<Flex
									flexDirection="column"
									width="100%"
									alignItems="center"
									justifyContent="space-between"
									height="100vh"
								>
									<Flex flexDirection="column" width="100%" alignItems="center">
										<Image
											objectFit={"contain"}
											w="50%"
											src={KKonnectLogo}
											m="0.8rem 0 2rem 0"
											cursor="pointer"
											onClick={() => {
												navigate("/fileupload");
												if (isMobileTablet) setCollapsed(true);
											}}
										/>

										{menuItems.map((item, index) => {
											return (
												<motion.div
													key={index}
													style={{
														width: "100%",
													}}
												>
													<IconMini
														key={item.text}
														mt="1em"
														mx="auto"
														Icon={item.icon}
														alignItems="center"
														active={activeTab === item.link ? true : false}
														_hover={{ color: "#0087ee" }}
														link={item.link ? item.link : "#"}
														onClick={() => setActiveTab(item.link)}
														text={item.sideBarText}
													/>
												</motion.div>
											);
										})}
									</Flex>
									{/* {subscriptionStatus()} */}
								</Flex>
							) : (
								// don't change the code here

								<Box padding="0 0.5rem">
									<Flex
										flexDirection="column"
										width="100%"
										justifyContent="space-between"
										height="100vh"
									>
										<Box>
											<Image
												w={isMobileTablet ? "70%" : "50%"}
												src={imageURL}
												m=".8rem 0 2rem .5rem"
												cursor="pointer"
												onClick={() => {
													navigate("/fileupload");
													if (isMobileTablet) setCollapsed(true);
												}}
											/>
											{isMobileTablet && (
												<Flex
													mb="1.5rem"
													ml="1rem"
													justifyContent="flex-start"
													alignItems="center"
													gap="1.25rem"
												>
													<Box
														borderRadius="50%"
														bg="rgba(0, 135, 238, 0.1)"
														color="rgba(0, 135, 238, 1)"
														width="3.5rem"
														height="3.5rem"
														display="flex"
														alignItems="center"
														justifyContent="center"
														cursor="pointer"
														onClick={() => navigate("/myprofile")}
													>
														<Text fontSize="2.5rem" fontWeight="600">
															{Cookies.get("full_name")
																?.charAt(0)
																.toUpperCase()}
														</Text>
													</Box>
													<Flex flexDir="column" gap=".375rem">
														<Box
															cursor="pointer"
															onClick={() => navigate("/myprofile")}
														>
															<Text
																textStyle="primary.contentTitle"
																fontWeight="600"
																wordBreak="break-word"
																lineHeight="1rem"
															>
																{Cookies.get("full_name")}
															</Text>
															<Text
																fontSize=".75rem"
																wordBreak="break-word"
																color="#00000090"
																lineHeight=".875rem"
															>
																{Cookies.get("user_id")}
															</Text>
														</Box>
														<Text
															textColor="rgba(0, 135, 238, 1)"
															onClick={handleSignout}
															cursor="pointer"
															fontSize=".875rem"
															fontWeight="semibold"
														>
															Sign Out
														</Text>
													</Flex>
												</Flex>
											)}
											{menuItems.map((item, index) => (
												<motion.div key={index} variants={sideVariants}>
													<Flex align="center">
														<IconText
															key={item.text}
															Icon={item.icon}
															text={item.text}
															fontSize="14px"
															p="1rem 0.5rem"
															mb="1rem"
															active={activeTab === item.link}
															_hover={{ color: "#0087ee" }}
															link={item.link}
															onClick={() => {
																setActiveTab(item.link);
																if (isMobileTablet) setCollapsed(true);
															}}
														/>
													</Flex>
												</motion.div>
											))}
										</Box>
										{isMobileTablet && (
											<Flex justifyContent="flex-end">
												<MdKeyboardDoubleArrowLeft
													size="2rem"
													color="#0087EE"
													cursor="pointer"
													onClick={() => setCollapsed(true)}
												/>
											</Flex>
										)}
										<Flex alignItems="center" justifyContent="center">
											{/* {subscriptionStatus()} */}
										</Flex>
									</Flex>
								</Box>
							)}
						</Flex>
					</motion.div>
				</Box>
			)}

			{/* Sidebar */}
			<Flex
				direction="column"
				w={
					collapsed
						? isMobileTablet
							? "100%"
							: "93%"
						: isMobileTablet
						? "100%"
						: "80%"
				}
			>
				<Box
					h="65px"
					bg="white"
					marginBottom="2px"
					flexShrink={0}
					borderLeft="1px solid #f3f6f9"
				>
					<Flex
						h="100%"
						alignItems="center"
						justifyContent="space-between"
						padding="10px"
						zIndex={999}
					>
						<Flex alignItems="center" fontWeight="600">
							{!isMobileTablet ? (
								<motion.div
									initial={{ scale: 1 }}
									animate={{ rotateY: !collapsed ? 180 : 0 }}
									transition={{ duration: 0.5 }}
								>
									<IconButton
										icon={<MdKeyboardDoubleArrowRight size={"100%"} />}
										boxSize={6}
										background={"none"}
										_hover={{ color: "#0087ee" }}
										_active={{ backgroundColor: "transparent" }}
										aria-label={
											collapsed ? "Expand sidebar" : "Collapse sidebar"
										}
										onClick={toggleCollapsed}
									/>
								</motion.div>
							) : (
								<motion.div
									initial={{ scale: 1 }}
									animate={{ rotateY: !collapsed ? 180 : 0 }}
									transition={{ duration: 0.5 }}
									style={{ marginRight: "0.8rem" }}
								>
									{collapsed && (
										<AiOutlineMenu
											size="1.5rem"
											onClick={toggleCollapsed}
											cursor="pointer"
											style={{ color: "#0087ee" }}
										/>
									)}
								</motion.div>
							)}
							<Flex alignItems="center" justifyContent="center" gap=".25rem">
								{isMobileTablet && pageHeader === "Configure Item" && (
									<MdArrowBackIosNew
										size="1.25rem"
										cursor="pointer"
										onClick={() => navigate(-1)}
									/>
								)}
								{pageHeader}
							</Flex>
						</Flex>
						<Flex
							direction="row"
							justifyContent="center"
							alignItems="center"
							px="2em"
							gap="1.5em"
						>
							{/* <Stack spacing="6" direction="row" alignItems="center">
								<Popover isLazy size="4xl" variant="responsive">
									<PopoverTrigger>
										<span>
											<BsBell size="20px" cursor="pointer" />
										</span>
									</PopoverTrigger>
									<PopoverContent position="relative" zIndex={99999}>
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverBody p="0px"></PopoverBody>
									</PopoverContent>
								</Popover>
							</Stack> */}
							{/* --------------current subscription plan-------------- */}
							{/* <Flex
								alignItems="center"
								gap=".625rem"
								p=".375rem .625rem"
								bg={subscriptionColorTheme?.box_background_color}
								borderRadius="150px"
							>
								<Image
									src={
										subscriptionData.plan?.toLowerCase().includes("pro")
											? ShieldZapGreen
											: checkVerifiedBlue
									}
								/>
								<Text
									fontSize=".75rem"
									fontWeight="semibold"
									lineHeight="15px"
									color={subscriptionColorTheme?.text_color}
								>
									{subscriptionData?.plan}
								</Text>
							</Flex> */}
							{/* --------------current subscription plan-------------- */}

							{!isMobileTablet && (
								<Box>
									<Stack direction="row" alignItems="center" gap="0.6em">
										<Box textAlign={"right"}>
											<Text
												fontWeight="normal"
												fontSize="14px"
												fontFamily="Rubik"
											>
												{Cookies.get("full_name") !== undefined
													? Cookies.get("full_name")?.split(" ")[0]
													: ""}
											</Text>
										</Box>
										<Menu
											isLazy={true}
											closeOnBlur={true}
											onClose={() => {
												setMenu(false);
											}}
										>
											<MenuButton
												transition="all 0.2s"
												onClick={() => {
													setMenu(!menu);
												}}
											>
												<Flex alignItems="center" gap="0.4em">
													<Flex
														rounded="full"
														width="26px"
														height="26px"
														bg="#0087ee30"
														fontWeight="600"
														alignItems="center"
														justifyContent="center"
														fontFamily="Rubik"
														color="#0087ee"
													>
														{Cookies.get("full_name")?.charAt(0).toUpperCase()}
													</Flex>
													<motion.div
														initial={{ scale: 1 }}
														animate={{ rotateX: menu ? 180 : 0 }}
														transition={{ duration: 0.5 }}
													>
														<BsChevronDown size="12" />
													</motion.div>
												</Flex>
											</MenuButton>
											<MenuList zIndex={9999} pt="0" boxShadow="lg">
												<MenuItem
													padding=".5rem .75rem"
													backgroundColor="#0087ee20"
													borderRadius="md"
												>
													<Flex gap="1em" alignItems="center">
														<Flex
															rounded="20%"
															width="2.5rem"
															height="2.5rem"
															bg="#0087ee"
															fontWeight="600"
															alignItems="center"
															justifyContent="center"
															fontFamily="Rubik"
															color="white"
															fontSize="1.875rem"
														>
															{Cookies.get("full_name")
																?.charAt(0)
																.toUpperCase()}
														</Flex>
														<Box>
															<Text fontWeight="600" fontFamily="Rubik" mb="0">
																{Cookies.get("full_name")}
															</Text>
															<Text
																fontFamily="Rubik"
																margin="0px"
																fontSize=".75rem"
																color="#00000090"
															>
																{Cookies.get("user_id")}
															</Text>
														</Box>
													</Flex>
												</MenuItem>
												<MenuItem
													icon={<IoPersonOutline color="#0087ee" size="1rem" />}
													onClick={() => navigate("/myprofile")}
												>
													<Text fontWeight="semibold" fontSize=".875rem">
														My Profile
													</Text>
												</MenuItem>
												<MenuDivider />
												<MenuItem
													icon={<FiLogOut color="#0087ee" size="1rem" />}
													onClick={handleSignout}
													borderRadius="md"
												>
													<Text fontWeight="semibold" fontSize=".875rem">
														Sign Out
													</Text>
												</MenuItem>
											</MenuList>
										</Menu>
									</Stack>
								</Box>
							)}
						</Flex>
					</Flex>
				</Box>
				<Flex bg="#f3f6f9" flexGrow="1" overflow="auto">
					{!!Component && <Component />}
				</Flex>
			</Flex>
			<BasicDialog
				isOpen={showPlans}
				onClose={() => {
					setShowPlans(false);
				}}
				showCloseButton={true}
				header="ACE Pricing Model"
				headerColor="#0087EE"
				headerTextAlign="center"
				size="6xl"
				content={
					<Box maxH="85vh" overflowY="auto" p=".5rem 0 1rem 0">
						<PricingModel
							continueWithPlan={(selected_plan: string) => {
								setSelectedPlan(selected_plan);
								setShowPlans(false);
								setShowPlanUpgradeConfirmBox(true);
							}}
						/>
					</Box>
				}
			/>

			<BasicDialog
				isOpen={showPlanUpgradeConfirmBox}
				onClose={() => {
					setShowPlanUpgradeConfirmBox(false);
					setShowPlans(true);
				}}
				showCloseButton={true}
				size="xl"
				showHeaderBottomBorder={true}
				header="Confirm Your Plan"
				content={
					<Flex
						alignItems="center"
						justifyContent="center"
						flexDir="column"
						p="1.75rem 3rem"
					>
						<Text>
							You have selected{" "}
							<span style={{ fontWeight: "600" }}>{selectedPlan}</span> plan.
							Please confirm to proceed. Our team will get in touch with you
							shortly.
						</Text>
						<Flex alignItems="enter" gap="1.25rem" mt="1.6875rem">
							<Button
								className="aceCancelPlanSelectionBtn"
								id="ace_cancel_plan_selection_btn"
								children="Cancel"
								variant="outlined"
								border="1px solid #0087EE"
								color="#0087EE"
								_hover={{ bg: "#0087EE20" }}
								isLoading={buttonLoading}
								onClick={() => {
									setShowPlanUpgradeConfirmBox(false);
									setShowPlans(true);
								}}
							/>
							<Button
								className="aceChangeToSelectedSubscriptionPlanBtn"
								id="ace_change_to_selected_subscription_plan_btn"
								children="Confirm"
								color="#fff"
								bg="#0087EE"
								_hover={{ bg: "#0066b4" }}
								isLoading={buttonLoading}
								onClick={changePlan}
							/>
						</Flex>
					</Flex>
				}
			/>

			{pageLoadingData?.loading && (
				<PageLoading
					pageLoading={pageLoadingData}
					fileUploadPageLoader={FileUploadPageLoader}
					pageLoaderURL={pageLoaderURL}
				/>
			)}
			<ProductFruits
				workspaceCode={
					process.env.REACT_APP_NODE_ENV === "production"
						? "nqa26qFiYPtoIVfc"
						: "nqa26qFiYPtoIVfc"
				}
				language="en"
				user={{
					username: String(Cookies.get("user_id")),
					email: String(Cookies.get("user_id")),
					firstname: String(Cookies.get("full_name")),
				}}
			/>
		</Flex>
	);
};
