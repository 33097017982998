import { SET_MESSAGE, CLEAR_MESSAGE, API_LOADING, PAGE_LOADING, UPDATE_ONBOARD_STATUS } from "./types";

export const setMessage = (message: any) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

export const setLoading = (loading: boolean = false) => ({
  type: API_LOADING,
  payload: loading,
});

export const setPageLoading = (loadingObj: any) => ({
  type: PAGE_LOADING,
  payload: loadingObj,
});

export const updateOnboardStatus = (val: boolean) => ({
  type: UPDATE_ONBOARD_STATUS,
  payload: val,
});
